import React, { useState } from 'react';
import styled from 'styled-components';
import { Eye, EyeOff, Copy } from 'lucide-react';
import Button from './Button';

const ApiContainer = styled.div`
  // ... (use similar styling as in App.js)
`;

const AuthKeyContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const AuthKey = styled.input`
  flex-grow: 1;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 0.5rem;
`;

const AuthKeyButton = styled(Button)`
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  background-color: #f0f0f0;
  color: #333;
  border: 1px solid #ddd;
  margin-left: 0.5rem;
  width: ${props => props.small ? '12.5%' : '25%'};

  &:hover {
    background-color: #e0e0e0;
  }
`;

const CodeBlockContainer = styled.div`
  position: relative;
`;

const CodeBlock = styled.pre`
  background-color: #f0f4f8;
  padding: 1rem;
  border-radius: 4px;
  overflow-x: auto;
`;

const CopyButton = styled(Button)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  background-color: #f0f0f0;
  color: #333;
  border: 1px solid #ddd;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const ApiPage = ({ apiUrl, idToken, queryInput, llmSetting }) => {
  const [showAuthKey, setShowAuthKey] = useState(false);

  const toggleAuthKey = () => setShowAuthKey(!showAuthKey);

  const copyAuthKey = () => {
    navigator.clipboard.writeText(idToken);
    alert('Auth key copied to clipboard!');
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert('Copied to clipboard!');
  };

  const curlExample = `curl -X POST ${apiUrl} \\
  -H "Content-Type: application/json" \\
  -H "Authorization: <Your Auth Key>" \\
  -d '{
    "messages": [{"role": "user", "content": "${queryInput}"}],
    "llm_setting": "${llmSetting}"
  }'`;

  const pythonExample = `# Install with \`pip install requests\`
# Set an environmental variable for the auth key

import requests
import json
import os

url = "${apiUrl}"
headers = {
    "Content-Type": "application/json",
    "Authorization": os.environ.get("TRELIS_AUTH_KEY")
}
data = {
    "messages": [{"role": "user", "content": "${queryInput}"}],
    "llm_setting": "${llmSetting}"
}

response = requests.post(url, headers=headers, json=data)
result = response.json()

print(f"Answer: {result['choices'][0]['message']['content']}")
print("Citations:")
for citation in result['trelis_info']['citations']:
    print(f"[{citation['number']}] {citation['title']} (Page: {citation['page']}): {citation['snippet']}")`;

  return (
    <ApiContainer>
      <h2>API Documentation</h2>
      <h3>Authentication Key</h3>
      <AuthKeyContainer>
        <AuthKey
          type={showAuthKey ? 'text' : 'password'}
          value={idToken}
          readOnly
        />
        {showAuthKey ? (
          <>
            <AuthKeyButton small onClick={copyAuthKey}>
              <Copy size={16} />
            </AuthKeyButton>
            <AuthKeyButton small onClick={toggleAuthKey}>
              <EyeOff size={16} />
            </AuthKeyButton>
          </>
        ) : (
          <AuthKeyButton onClick={toggleAuthKey}>
            <Eye size={16} />
          </AuthKeyButton>
        )}
      </AuthKeyContainer>
      <p><em>Note: This key will expire within 1 hour. For a commercial key, please contact hello@trelis.com</em></p>

      <h3>cURL Example</h3>
      <CodeBlockContainer>
        <CodeBlock>{curlExample}</CodeBlock>
        <CopyButton onClick={() => copyToClipboard(curlExample)}>
          <Copy size={16} />
        </CopyButton>
      </CodeBlockContainer>

      <h3>Python Example</h3>
      <CodeBlockContainer>
        <CodeBlock>{pythonExample}</CodeBlock>
        <CopyButton onClick={() => copyToClipboard(pythonExample)}>
          <Copy size={16} />
        </CopyButton>
      </CodeBlockContainer>

      <h3>Response Format</h3>
      <CodeBlockContainer>
        <CodeBlock>{`{
  "choices": [
    {
      "message": {
        "content": "The answer to your query..."
      }
    }
  ],
  "trelis_info": {
    "citations": [
      {
        "number": 1,
        "title": "Document Title",
        "page": "Page number or range (if available, otherwise empty string)",
        "snippet": "Exact snippet from the document"
      },
      // ... more citations
    ]
  }
}`}</CodeBlock>
        <CopyButton onClick={() => copyToClipboard(`{
  "choices": [
    {
      "message": {
        "content": "The answer to your query..."
      }
    }
  ],
  "trelis_info": {
    "citations": [
      {
        "number": 1,
        "title": "Document Title",
        "page": "Page number or range (if available, otherwise empty string)",
        "snippet": "Exact snippet from the document"
      },
      // ... more citations
    ]
  }
}`)}>
          <Copy size={16} />
        </CopyButton>
      </CodeBlockContainer>
    </ApiContainer>
  );
};

export default ApiPage;