/** @format */

import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import Button from "./Button";
import { X } from "lucide-react";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailLink,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
} from "firebase/auth";
import trelisLogo from "./apple-touch-icon.png"; // Adjust the path as needed
import { loadStripe } from "@stripe/stripe-js";
import { ToggleLeft } from 'lucide-react'; // Change this import
import { Loader } from 'lucide-react'; // Add this import
import { AlertTriangle } from 'lucide-react'; // Add this import
import ApiPage from './ApiPage'; // We'll create this component

// Import the ring loader from ldrs
import { quantum } from "ldrs";
quantum.register();

// Near the top of your file, after imports
const API_BASE_URL = process.env.REACT_APP_API_URL || "https://trelis-endpoints-d73706073fdd.herokuapp.com";

console.log("Current API Base URL:", API_BASE_URL);

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC8geFdBIwhvntwaDjsx1U6u_O803UNC3c",
  authDomain: "trelis-endpoint.firebaseapp.com",
  projectId: "trelis-endpoints",
  storageBucket: "trelis-endpoints.appspot.com",
  messagingSenderId: "730774950338",
  appId: "1:730774950338:web:a8b578d1a1ec6d0163065b",
  measurementId: "G-7XFVE7F62F",
};

const stripe_publishable_key = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(stripe_publishable_key);
const MAX_FILE_SIZE = parseInt(process.env.REACT_APP_MAX_FILE_SIZE_MB, 10) * 1024 * 1024 || 5 * 1024 * 1024; // 5 MB in bytes
const MAX_TOTAL_FILES = parseInt(process.env.REACT_APP_MAX_TOTAL_FILES, 10) || 5;

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f4f8;
  font-family: "Arial", sans-serif;
`;

const Card = styled(motion.div)`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h1`
  color: #093b61;
  text-align: center;
  margin-bottom: 1.5rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
`;

const FileUploadContainer = styled.div`
  margin-top: 1rem;
`;

const FileList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const CredentialItem = styled.div`
  background-color: #e8f0fe;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;

  strong {
    color: #4a90e2;
  }
`;

const MessageContainer = styled.div`
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
  word-break: break-word;
  max-width: 100%;
  overflow-wrap: break-word;
`;

const SuccessMessage = styled(MessageContainer)`
  background-color: #d4edda;
  color: #155724;
  word-break: break-word;
  max-width: 100%;
  overflow-wrap: break-word;
`;

const ErrorMessage = styled(MessageContainer)`
  background-color: #f8d7da;
  color: #721c24;
  word-break: break-word;
  max-width: 100%;
  overflow-wrap: break-word;
`;

const QueryInput = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  min-height: 100px;
  resize: vertical;
`;

const QueryResult = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f0f0f0;
  border-radius: 4px;
  white-space: pre-wrap;
  max-height: 300px; // Add this line
  overflow-y: auto; // Add this line
`;

const WalletBalance = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: #093b61;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-weight: bold;
`;

const FileItem = styled.li`
  background-color: #e8f0fe;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    flex-grow: 1;
    margin-right: 8px;
    word-break: break-all;
  }
`;

const FileLink = styled.a`
  color: #4a90e2;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(
    100% - 30px
  ); // Adjust based on the size of your remove button
`;

const RemoveButton = styled.button`
  background-color: #ff4d4f;
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
  margin-left: auto;
  flex-shrink: 0;

  &:hover {
    background-color: #ff7875;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const Logo = styled.img`
  width: 50px;
  height: auto;
  margin-bottom: 1rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const TopUpInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
`;

const StoredFileList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 1rem;
  width: 100%;
`;

const StoredFileItem = styled.li`
  background-color: #f0f4f8;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
`;

const LowBalanceWarning = styled.div`
  background-color: #fff3cd;
  color: #856404;
  padding: 0.75rem;
  margin-top: 1rem;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
`;

const Citation = styled.div`
  background-color: #e8f0fe;
  border-left: 3px solid #4a90e2;
  padding: 0.5rem;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  word-break: break-word;
`;

const CitationNumber = styled.span`
  font-weight: bold;
  margin-right: 0.5rem;
`;

const CitationPage = styled.span`
  font-style: italic;
  color: #666;
  margin-left: 0.5rem;
`;

const UploadLimitsInfo = styled.div`
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #666;
  background-color: #f0f4f8;
  padding: 0.5rem;
  border-radius: 4px;
`;

const WarningMessage = styled.div`
  color: #d4351c;
  font-weight: bold;
  margin-top: 0.5rem;
`;

const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`;

const MainTitle = styled.h1`
  color: #093b61;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
`;

const Tagline = styled.h2`
  color: #4a90e2;
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;

const FeatureList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: 2rem;
  text-align: center;
`;

const FeatureItem = styled.li`
  margin-bottom: 0.5rem;
  display: inline-block;
  text-align: center;
  width: 80%;

  &:before {
    content: "•";
    color: #4a90e2;
    font-weight: bold;
    margin-right: 0.5rem;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 10px;
  transition: transform 0.3s ease;
  transform: ${props => props.active ? 'rotate(180deg)' : 'rotate(0)'};

  svg {
    color: #093b61;
  }
`;

const ToggleLabel = styled.span`
  margin: 0 0.5rem;
  font-weight: ${props => props.active ? 'bold' : 'normal'};
  color: ${props => props.active ? '#093b61' : '#4a90e2'};
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-top: 10px;
`;

const Progress = styled.div`
  width: ${props => props.percent}%;
  height: 100%;
  background-color: #4caf50;
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
`;

const ProgressDetails = styled.div`
  margin-top: 10px;
  font-size: 0.9em;
  color: #555;
  word-break: break-word;
`;

const NavBar = styled.nav`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  border-bottom: 1px solid #e0e0e0;
`;

const NavItem = styled.button`
  background: none;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  color: ${props => props.active ? '#007bff' : '#333'};
  border-bottom: ${props => props.active ? '2px solid #007bff' : 'none'};

  &:hover {
    color: #007bff;
  }
`;

const App = () => {
  const [step, setStep] = useState("login");
  const [email, setEmail] = useState("");
  const [apiUrl, setApiUrl] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadMessage, setUploadMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [queryInput, setQueryInput] = useState("What is... ?");
  const [queryResult, setQueryResult] = useState("");
  const [user, setUser] = useState(null);
  const [walletAmount, setWalletAmount] = useState(0);
  const [userId, setUserId] = useState("");
  const [topUpAmount, setTopUpAmount] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [showLowBalanceWarning, setShowLowBalanceWarning] = useState(false);
  const [isQuerying, setIsQuerying] = useState(false);
  const [citations, setCitations] = useState([]);
  const [fileCountWarning, setFileCountWarning] = useState("");
  const [sizeWarning, setSizeWarning] = useState("");
  const [idToken, setIdToken] = useState(null);
  const [availableFiles, setAvailableFiles] = useState([]);
  const [llmSetting, setLlmSetting] = useState('speed');
  const [uploadStatus, setUploadStatus] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [currentFile, setCurrentFile] = useState('');
  const [totalFiles, setTotalFiles] = useState(0);
  const [uploadErrors, setUploadErrors] = useState([]);
  const [currentPage, setCurrentPage] = useState('assistant');

  useEffect(() => {
    const fetchUserId = async () => {
      if (user) {
        setUserId(user.uid);
      }
    };

    fetchUserId();
  }, [user]);

  useEffect(() => {
    if (user) {
      const fetchWalletBalance = async () => {
        try {
          const idToken = await user.getIdToken();
          const response = await fetch(`${API_BASE_URL}/wallet-balance`, {
            method: "GET",
            headers: {
              Authorization: idToken,
            },
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          setWalletAmount(Number(data.wallet_balance));
        } catch (error) {
          console.error("Error fetching wallet balance:", error);
          setWalletAmount(0);
        }
      };

      fetchWalletBalance();
    }
  }, [user]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        setStep("upload");
      } else {
        setUser(null);
        setStep("login");
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (email) {
      const actionCodeSettings = {
        url: window.location.href,
        handleCodeInApp: true,
      };

      try {
        await sendSignInLinkToEmail(auth, email, actionCodeSettings);
        window.localStorage.setItem("emailForSignIn", email);
        setUploadMessage(
          "Sign-in link sent to your email. Please check your inbox."
        );
        setIsError(false);
      } catch (error) {
        setUploadMessage(`Error sending sign-in link: ${error.message}`);
        setIsError(true);
      }
    } else {
      setUploadMessage("Please enter your email address.");
      setIsError(true);
    }
  };

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        email = window.prompt("Please provide your email for confirmation");
      }
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          window.localStorage.removeItem("emailForSignIn");
          setUser(result.user);
          setStep("upload");
        })
        .catch((error) => {
          setUploadMessage(
            `Error signing in with email link: ${error.message}`
          );
          setIsError(true);
        });
    }
  }, []);

  const handleBackToUpload = async () => {
    setStep("upload");
    setUploadMessage("");
    setIsError(false);
    await fetchAvailableFiles();
  };

  const handleLogout = () => {
    auth.signOut().then(() => {
      setStep("login");
      setEmail("");
      setApiUrl("");
      setUploadMessage("");
      setIsError(false);
      setUser(null);
    });
  };

  const handleFileUpload = (e) => {
    const newFiles = Array.from(e.target.files);
    const validFiles = newFiles.filter(file => 
      (file.type === 'application/pdf' || 
       file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
       file.type === 'text/plain' ||
       file.type === 'text/markdown' ||
       file.type === 'text/markdown') &&
      file.size <= MAX_FILE_SIZE
    );
  
    const errors = [];
    newFiles.forEach(file => {
      if (file.size > MAX_FILE_SIZE) {
        errors.push(`${file.name} exceeds the ${MAX_FILE_SIZE / (1024 * 1024)} MB limit.`);
      } else if (!validFiles.includes(file)) {
        errors.push(`${file.name} is not a supported file type.`);
      }
    });
  
    setUploadErrors(errors);
  
    if (selectedFiles.length + validFiles.length > MAX_TOTAL_FILES) {
      setFileCountWarning(`You can only select up to ${MAX_TOTAL_FILES} files at a time.`);
      return;
    }
  
    setSelectedFiles(prevFiles => [...prevFiles, ...validFiles]);
    setFileCountWarning("");
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setFileCountWarning("");
    setSizeWarning("");
  };

  const toggleLlmSetting = () => {
    const newSetting = llmSetting === 'speed' ? 'quality' : 'speed';
    setLlmSetting(newSetting);
    console.log(`LLM approach switched to: ${newSetting}`);
  };

  const handleQuery = async () => {
    setIsQuerying(true);
    console.log(`Querying with ${llmSetting} approach`);
    try {
      const idToken = await user.getIdToken();
      const response = await fetch(`${API_BASE_URL}/query`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: idToken,
        },
        body: JSON.stringify({
          messages: [{ role: "user", content: queryInput }],
          user_id: userId,
          llm_setting: llmSetting,
        }),
        credentials: 'include',
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        if (response.status === 402) {
          // Handle insufficient balance
          setQueryResult("Insufficient balance to process this query.");
          setUploadMessage(`Your current balance is $${data.trelis_info.current_balance.toFixed(2)}. You need $${data.trelis_info.additional_funds_needed.toFixed(2)} more to process this query.`);
          setIsError(true);
          setShowLowBalanceWarning(true);
        } else if (response.status === 400 && data.error.includes('Total message length exceeds maximum')) {
          // Handle message length exceed error
          setQueryResult("Your input messages exceed 4,000 characters. Reach out to ronan @ trelis.com for longer context support.");
          setUploadMessage(data.error);
          setIsError(true);
        } else {
          throw new Error(data.error || `HTTP error! status: ${response.status}`);
        }
      } else {
        setQueryResult(data.choices[0].message.content || "No answer received");
        setCitations(data.trelis_info.citations || []);
        setAvailableFiles(data.trelis_info.file_metadata || []);
  
        // Update wallet balance
        if (data.trelis_info.new_balance !== undefined) {
          setWalletAmount(Number(data.trelis_info.new_balance));
          if (data.trelis_info.new_balance <= 0) {
            setShowLowBalanceWarning(true);
          }
        }
      }
    } catch (error) {
      console.error("Error during query:", error);
      setQueryResult("An error occurred handling the query. Kindly report the error to Ronan at trelis dot com.");
      setCitations([]);
    } finally {
      setIsQuerying(false);
    }
  };

  const handleDirectQuery = async () => {
    try {
      const token = await user.getIdToken();
      setIdToken(token);
  
      // Set the API URL
      setApiUrl(`${API_BASE_URL}/query`);
  
      setStep("credentials");
      setUploadMessage("Ready to query stored documents.");
      setIsError(false);
    } catch (error) {
      console.error("Error preparing for query:", error);
      setUploadMessage("An error occurred while preparing to query. Please try again.");
      setIsError(true);
    }
  };

  const checkUploadStatus = async (jobId, idToken) => {
    setUploadStatus('Initializing upload...');
    setTotalFiles(selectedFiles.length);
  
    const maxRetries = 100; // Increase the number of retries
    const baseDelay = 2500; // 1 second
  
    for (let retry = 0; retry < maxRetries; retry++) {
      try {
        console.log(`Checking status for job ${jobId}, attempt ${retry + 1}`);
        const response = await fetch(`${API_BASE_URL}/upload-status/${jobId}`, {
          headers: {
            Authorization: idToken,
          },
        });

        console.log(`Status response for job ${jobId}:`, response.status);

        if (!response.ok) {
          if (response.status === 404) {
            // If job is not found, wait and retry
            const delay = baseDelay * Math.pow(2, retry);
            console.log(`Job ${jobId} not found, waiting ${delay}ms before retry`);
            setUploadStatus(`Waiting for job to start... (Attempt ${retry + 1}/${maxRetries})`);
            await new Promise(resolve => setTimeout(resolve, delay));
            continue;
          }
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log(`Job ${jobId} status:`, data);

        if (data.status === 'completed') {
          setUploadStatus('Upload completed');
          setUploadProgress(100);
          setUploadMessage(data.result?.message || "Files uploaded successfully!");
          setIsError(false);
          setSelectedFiles([]);
          await fetchAvailableFiles();
          setTimeout(() => {
            setStep("credentials");
            setUploadMessage("");
            setUploadStatus(null);
            setUploadProgress(0);
            setCurrentFile('');
          }, 2000);
          break;
        } else if (data.status === 'failed') {
          setUploadStatus('Upload failed');
          setUploadMessage(data.error || "An error occurred during upload.");
          setIsError(true);
          break;
        } else if (data.status === 'in_progress') {
          const { current_file, file_number, total_files } = data.progress || {};
          if (current_file && file_number && total_files) {
            setCurrentFile(current_file);
            setUploadProgress((file_number / total_files) * 100);
            setUploadStatus(`Processing file ${file_number} of ${total_files}`);
            setTotalFiles(total_files);
          } else {
            setUploadStatus('Processing files...');
          }
        } else {
          setUploadStatus('Waiting for server...');
        }
      } catch (error) {
        console.error(`Error checking status for job ${jobId}:`, error);
        setUploadStatus('Error checking status');
        setUploadMessage("An error occurred while checking upload status. Please try again.");
        setIsError(true);
        break;
      }

      // Increase the wait time between checks
      await new Promise(resolve => setTimeout(resolve, 3000));
    }
  };

  const handleUploadSubmit = async () => {
    if (selectedFiles.length === 0) {
      setUploadMessage("Please select files to upload.");
      setIsError(true);
      return;
    }
  
    setIsUploading(true);
    setUploadStatus('Uploading files...');
    setUploadProgress(0);
    setTotalFiles(selectedFiles.length);
    setCurrentFile('');
  
    // Create FormData object
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });
  
    try {
      const idToken = await user.getIdToken();
      const response = await fetch(`${API_BASE_URL}/upload`, {
        method: "POST",
        headers: {
          Authorization: idToken,
        },
        body: formData,
        credentials: 'include',
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }
  
      const { job_id } = await response.json();
      console.log("Received job_id:", job_id);
      await checkUploadStatus(job_id, idToken);
    } catch (error) {
      console.error("Error uploading files:", error);
      setUploadStatus('Upload failed');
      setUploadMessage(error.message || "An error occurred during upload.");
      setIsError(true);
    } finally {
      setIsUploading(false);
    }
  };

  const handleTopUp = async () => {
    const amount = parseFloat(topUpAmount);
    if (isNaN(amount) || amount <= 0) {
      setUploadMessage("Please enter a valid amount.");
      setIsError(true);
      return;
    }

    try {
      const stripe = await stripePromise;
      const idToken = await user.getIdToken();

      const response = await fetch(`${API_BASE_URL}/create-checkout-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: idToken,
        },
        body: JSON.stringify({ amount }),
      });

      const { sessionId } = await response.json();

      const result = await stripe.redirectToCheckout({
        sessionId: sessionId,
      });

      if (result.error) {
        setUploadMessage(result.error.message);
        setIsError(true);
      }
    } catch (error) {
      console.error("Error during top-up:", error);
      setUploadMessage("An error occurred during top-up. Please try again and report the error to Ronan at trelis dot com.");
      setIsError(true);
    }
  };
  const renderWalletTopUp = () => (
    <div>
      <center>
        <h3>Top Up Your Wallet</h3>
        <TopUpInput
          type="number"
          placeholder="Enter amount to add"
          value={topUpAmount}
          onChange={(e) => setTopUpAmount(e.target.value)}
          min="0"
          step="0.01"
        />
        <CredentialItem>
          <Button onClick={handleTopUp}>Add Funds</Button>
        </CredentialItem>
      </center>
    </div>
  );

  const handleRemoveStoredFile = async (fileName) => {
    if (!fileName) {
      setUploadMessage("Error: Filename is required for deletion.");
      setIsError(true);
      return;
    }
  
    try {
      const idToken = await user.getIdToken();
      const response = await fetch(`${API_BASE_URL}/delete-file`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': idToken,
        },
        body: JSON.stringify({ filename: fileName }),
        credentials: 'include',
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log("File deletion response:", data);
      setUploadMessage(`File ${fileName} removed successfully.`);
      setIsError(false);
  
      // Refresh the list of available files
      await fetchAvailableFiles();
    } catch (error) {
      console.error("Error removing file:", error);
      setUploadMessage(`Error removing file ${fileName}: ${error.message}`);
      setIsError(true);
    }
  };

  // In the fetchAvailableFiles function, ensure all file types are being returned
  const fetchAvailableFiles = useCallback(async () => {
    if (!user) return;
  
    try {
      const idToken = await user.getIdToken();
      const response = await fetch(`${API_BASE_URL}/list-files`, {
        method: 'GET',
        headers: {
          'Authorization': idToken,
          'Content-Type': 'application/json'
        },
        credentials: 'include',
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      setAvailableFiles(data.files);
    } catch (error) {
      console.error("Error fetching available files:", error);
      setUploadMessage("Error fetching available files. Please try again.");
      setIsError(true);
    }
  }, [user, setAvailableFiles, setUploadMessage, setIsError]);

  useEffect(() => {
    if (user) {
      fetchAvailableFiles();
    }
  }, [user, fetchAvailableFiles]);

  const renderNavigation = () => (
    <NavBar>
      <NavItem 
        onClick={() => setCurrentPage('assistant')} 
        active={currentPage === 'assistant'}
      >
        Assistant
      </NavItem>
      <NavItem 
        onClick={() => setCurrentPage('api')} 
        active={currentPage === 'api'}
      >
        API
      </NavItem>
    </NavBar>
  );

  return (
    <Container>
      <Card
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
      >
        <Logo src={trelisLogo} alt="Trelis Logo" />
        {user && step === "credentials" && renderNavigation()}
        {currentPage === 'assistant' ? (
          <>
            {step === "login" && (
              <>
                <TitleContainer>
                  <MainTitle>Trelis Assistant</MainTitle>
                  <Tagline>Build an Assistant in Seconds</Tagline>
                  <FeatureList>
                    <FeatureItem>Upload your documents</FeatureItem>
                    <FeatureItem>Get answers with verified citations</FeatureItem>
                    <FeatureItem>Query on-screen or via API</FeatureItem>
                    <FeatureItem>Ultra-fast Responses</FeatureItem>
                  </FeatureList>
                </TitleContainer>
                <Input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Button onClick={handleLogin}>Send Sign-In Link</Button>
              </>
            )}
            {step === "upload" && (
              <>
                <Title>Upload Documents</Title>
                <FileUploadContainer>
                  <Input
                    type="file"
                    multiple
                    accept=".pdf,.docx,.txt,.md"
                    onChange={handleFileUpload}
                  />
                  <FileList>
                    {selectedFiles.map((file, index) => (
                      <FileItem key={`selected-${index}`}>
                        <div>{file.name}</div>
                        <RemoveButton onClick={() => handleRemoveFile(index)}>
                          <X size={16} />
                        </RemoveButton>
                      </FileItem>
                    ))}
                  </FileList>
                  {fileCountWarning && (
                    <WarningMessage>{fileCountWarning}</WarningMessage>
                  )}
                  {sizeWarning && <WarningMessage>{sizeWarning}</WarningMessage>}
                  <UploadLimitsInfo>
                    <strong>Upload Limits:</strong>
                    <ul>
                      <li>Maximum number of files: {MAX_TOTAL_FILES}</li>
                      <li>Maximum file size: {MAX_FILE_SIZE / (1024 * 1024)} MB</li>
                      <li>Accepted file type: PDF, TXT, DOCX, MD</li>
                    </ul>
                    <p>
                      <em>
                        Note: Files exceeding {MAX_FILE_SIZE / (1024 * 1024)} MB size will be automatically
                        skipped.
                      </em>
                    </p>
                  </UploadLimitsInfo>
                  {isUploading ? (
                    <LoaderContainer>
                      <Loader size={40} color="#093b61" />
                      <div>{uploadStatus}</div>
                      <ProgressBar>
                        <Progress percent={uploadProgress} />
                      </ProgressBar>
                      <ProgressDetails>
                        {currentFile ? (
                          <>Current file: {currentFile}</>
                        ) : (
                          totalFiles > 0 ? `Processing ${totalFiles} files...` : 'Preparing to process files...'
                        )}
                      </ProgressDetails>
                    </LoaderContainer>
                  ) : (
                    <Button
                      onClick={handleUploadSubmit}
                      disabled={selectedFiles.length === 0 || !!fileCountWarning}
                    >
                      Upload Files
                    </Button>
                  )}
                </FileUploadContainer>
                <StoredFileList>
                  <h3>Available Files:</h3>
                  {availableFiles.map((file, index) => (
                    <StoredFileItem key={`stored-${index}`}>
                      <FileLink>{typeof file === 'string' ? file : file.filename}</FileLink>
                      <RemoveButton
                        onClick={() => handleRemoveStoredFile(typeof file === 'string' ? file : file.filename)}
                      >
                        <X size={16} />
                      </RemoveButton>
                    </StoredFileItem>
                  ))}
                </StoredFileList>
                {availableFiles.length > 0 && (
                  <Button onClick={handleDirectQuery}>Query Documents</Button>
                )}
                {uploadErrors.length > 0 && (
                  <ErrorMessage>
                    <AlertTriangle size={16} />
                    <ul>
                      {uploadErrors.map((error, index) => (
                        <li key={index}>{error}</li>
                      ))}
                    </ul>
                  </ErrorMessage>
                )}
              </>
            )}
            {step === "credentials" && (
              <>
                {user && (
                  <WalletBalance>
                    Wallet: ${typeof walletAmount === 'number' ? walletAmount.toFixed(2) : '0.00'}
                  </WalletBalance>
                )}
                {showLowBalanceWarning && (
                  <LowBalanceWarning>
                    You've used all your free requests and your wallet balance is
                    low. Please add funds to continue using the service.
                  </LowBalanceWarning>
                )}
                <CredentialItem>
                  <Button onClick={handleBackToUpload}>
                    Upload More Documents
                  </Button>
                </CredentialItem>
                <h3>
                  <center>
                    <strong>Query your Assistant</strong>
                  </center>
                </h3>
                <ToggleContainer>
                  <ToggleLabel active={llmSetting === 'speed'}>Speed</ToggleLabel>
                  <ToggleButton onClick={toggleLlmSetting} active={llmSetting === 'quality'}>
                    <ToggleLeft size={24} />
                  </ToggleButton>
                  <ToggleLabel active={llmSetting === 'quality'}>Quality</ToggleLabel>
                </ToggleContainer>
                <QueryInput
                  value={queryInput}
                  onChange={(e) => setQueryInput(e.target.value)}
                  placeholder="Enter your query here"
                />
                {isQuerying ? (
                  <LoaderContainer>
                    <l-quantum size="60" color="#093b61"></l-quantum>
                  </LoaderContainer>
                ) : (
                  <Button onClick={handleQuery}>Test Query</Button>
                )}
                {queryResult && (
                  <QueryResult>
                    <h3>Query Result:</h3>
                    <p>{queryResult}</p>
                    {citations.length > 0 && (
                      <div>
                        <h4>Citations:</h4>
                        {citations.map((citation, index) => (
                          <Citation key={index}>
                            <CitationNumber>[{citation.number}]</CitationNumber>
                            <strong>{citation.title}{citation.page && (
                            <CitationPage>(Page: {citation.page})</CitationPage>
                            )}:</strong> {citation.snippet}
                          </Citation>
                        ))}
                      </div>
                    )}
                  </QueryResult>
                )}
                <p>
                  <i>
                    Pricing: $7 per 1000 requests for speed. $70 per 1000 requests for quality
                  </i>
                </p>
                {renderWalletTopUp()}
              </>
            )}
            <AnimatePresence>
              {uploadMessage && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.3 }}
                >
                  {isError ? (
                    <ErrorMessage>{uploadMessage}</ErrorMessage>
                  ) : (
                    <SuccessMessage>{uploadMessage}</SuccessMessage>
                  )}
                </motion.div>
              )}
            </AnimatePresence>
            
            <br /><br />
            {user && <Button onClick={handleLogout}>Logout</Button>}
          </>
        ) : (
          <ApiPage
            apiUrl={apiUrl}
            idToken={idToken}
            queryInput={queryInput}
            userId={userId}
            llmSetting={llmSetting}
          />
        )}
      </Card>
    </Container>
  );
};

export default App;
