/** @format */

import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const StyledButton = styled(motion.button)`
  width: 100%;
  padding: 12px;
  background-color: #093b61;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

  &:hover {
    background-color: #093b61;
  }

  &:focus {
    outline: none;
  }
`;

const Button = ({ children, onClick }) => {
  return (
    <StyledButton
      onClick={onClick}
      whileHover={{
        scale: 1.05,
        boxShadow:
          "0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)",
        backgroundColor: "#135a9d",
      }}
      whileTap={{
        scale: 0.95,
        boxShadow:
          "0 2px 4px rgba(50, 50, 93, 0.1), 0 1px 2px rgba(0, 0, 0, 0.08)",
        backgroundColor: "#093B61",
      }}
      transition={{
        type: "spring",
        stiffness: 400,
        damping: 17,
      }}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
